<template>
  <v-card-title>Latest Videos</v-card-title>

  <v-list v-if="!loading" class="py-0">
    <v-list-item
        v-for="video in videos"
        :key="video.id"
        :href="`https://www.youtube.com/watch?v=${video.id}`"
        target="_blank"
        rel="noopener noreferrer"
    >
      <template v-slot:prepend>
        <div class="mr-3" style="width: 80px; height: 45px; position: relative;">
          <v-img
              :src="video.thumbnail"
              :alt="video.title"
              width="80"
              height="45"
              cover
              class="video-thumbnail"
          >
            <div class="play-overlay d-flex align-center justify-center">
              <v-icon size="24" color="white">mdi-play-circle</v-icon>
            </div>
          </v-img>
        </div>
      </template>

      <v-list-item-title class="video-title">{{ video.title }}</v-list-item-title>
      <v-list-item-subtitle>{{ formatDate(video.publishedAt) }}</v-list-item-subtitle>
    </v-list-item>
  </v-list>

  <v-sheet v-else class="pa-4 d-flex flex-column align-center">
    <v-progress-circular indeterminate color="red"></v-progress-circular>
    <span class="mt-2">Loading videos...</span>
  </v-sheet>

  <v-alert v-if="error" type="error" class="mx-4 mt-2">{{ error }}</v-alert>

  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn
        color="red"
        variant="text"
        :href="channelUrl"
        target="_blank"
        rel="noopener noreferrer"
    >
      <v-icon class="mr-2">mdi-youtube</v-icon>
      See All
    </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  name: 'YoutubeLatestSection',
  data() {
    return {
      videos: [],
      loading: true,
      error: null,
      channelId: 'UC29MxGKgouMyhuVDzzb9MXA', // Replace with your YouTube channel ID
      channelUrl: 'https://www.youtube.com/channel/UC29MxGKgouMyhuVDzzb9MXA?sub_confirmation=1', // Replace with your channel URL
      apiKey: 'AIzaSyBsbIx2_1WI7h8jd5t7Gg7UtorHOU_G3lU' // Add this to your .env file
    }
  },
  mounted() {
    this.fetchLatestVideos()
  },
  methods: {
    async fetchLatestVideos() {
      try {
        this.loading = true
        const response = await fetch(
            `https://www.googleapis.com/youtube/v3/search?key=${this.apiKey}&channelId=${this.channelId}&part=snippet,id&order=date&maxResults=5&type=video`
        )

        if (!response.ok) {
          throw new Error('Failed to fetch videos')
        }

        const data = await response.json()

        this.videos = data.items.map(item => ({
          id: item.id.videoId,
          title: item.snippet.title,
          description: item.snippet.description,
          thumbnail: item.snippet.thumbnails.medium.url,
          publishedAt: item.snippet.publishedAt
        }))

        this.loading = false
      } catch (error) {
        console.error('Error fetching YouTube videos:', error)
        this.error = 'Unable to load videos.'
        this.loading = false
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    }
  }
}
</script>

<style scoped>
.video-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.9rem !important;
  line-height: 1.3;
}

.video-thumbnail {
  position: relative;
}

.play-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.v-list-item:hover .play-overlay {
  opacity: 1;
}
</style>
