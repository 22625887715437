<template>
  <v-layout v-bind="$attrs">
    <v-main :min-height="$vuetify.display.mdAndUp ? 500 : 200" style="border-bottom:1px solid #333;padding-top:0;">
      <div class="engine-logo godot-logo hidden-xs">
        <v-img src="/images/godot.svg" width="400" height="400" />
      </div>

      <div class="engine-logo unity-logo hidden-xs">
        <v-img src="/images/unity.svg" width="400" height="400" />
      </div>

      <v-container class="h-100 d-flex align-center justify-start pa-6" >
        <div class="w-100 w-md-50" style="z-index:1">
          <div class="mb-4 mb-md-8">
            <v-img v-if="$vuetify.display.mdAndUp" src="/images/simmernewyellow.png"
                   :width="75"
                   :height="75"/>
          </div>

          <h1 class="text-h4 text-md-h2 mb-6 mt-1">
            <span class="font-weight-bold">{{isSMG ? 'sharemygame' : 'simmer'}}</span>{{isSMG ? '': '.io'}}
          </h1>

          <div class="text-body-1 text-medium-emphasis mb-3">
            <span class="font-weight-bold">simmer</span> is a place for unity<span v-if="gating.showGodot"> and godot</span> developers to <span class="font-weight-bold">host</span> their games on the web for free.
          </div>
          <div class="text-body-1 text-medium-emphasis mb-6">
            {{stats}}
          </div>

          <div class="d-flex ga-4 justify-start">
            <UploadMenu />
            <HowToMenu />

          </div>
          <div class="text-body-1 text-medium-emphasis mt-5 pa-4" style="border:1px solid #cd1c55; background-color:rgba(0,0,0,0.3)">
            <div v-if="gating.showGodot">
              <span class="font-weight-bold>">Mar 24, 2025:</span> We now support Godot uploads! Anything broken? <a href="mailto:support@simmer.io">support@simmer.io</a> | <a href="https://discord.gg/2wGJeH6">discord</a> | <a href="https://legacy.simmer.io" target="_blank">old site</a>
            </div>
            <div v-else>
              <span class="font-weight-bold>">Feb 15, 2025:</span> We just relaunched! Anything broken? <a href="mailto:support@simmer.io">support@simmer.io</a> | <a href="https://discord.gg/2wGJeH6">discord</a> | <a href="https://legacy.simmer.io" target="_blank">old site</a>
            </div>
          </div>
        </div>


        <div class="v-bg position-absolute top-0 right-0 left-0 bottom-0">
          <div aria-hidden="true" class="overflow-hidden opacity-20 w-100 h-100" />
        </div>
      </v-container>

    </v-main>

  </v-layout>
  <YoutubeOverlay ref="youtubeOverlayUnity" video-url="https://www.youtube.com/embed/ZyeB3_rYM1E" style="z-index:100000"></YoutubeOverlay>
  <YoutubeOverlay ref="youtubeOverlayGodot" video-url="https://www.youtube.com/embed/ZyeB3_rYM1E" style="z-index:100000"></YoutubeOverlay>

</template>

<script setup>
import YoutubeOverlay from "~/components/generic/YoutubeOverlay.vue";
import useGating from "~/composables/useGating.js";
import HowToMenu from "~/components/HowToMenu.vue";
import UploadMenu from "~/components/UploadMenu.vue";

const gating = useGating();
console.log('gating', gating.value)
const youtubeOverlayUnity = ref(null);
const youtubeOverlayGodot = ref(null);

const props = defineProps({
  stats: {
    type: String,
    default: '',
  }
});

const isSMG = useIsSMG();

</script>

<style scoped>
.v-bg {
  filter: blur(128px);
  pointer-events: none;
}

.v-bg > div {
  background: linear-gradient(
      to bottom right,
      rgb(var(--v-theme-surface-variant)),
      rgb(var(--v-theme-primary))
  );
  z-index: -10;
  clip-path: polygon(5% 20%, 15% 10%, 30% 15%, 40% 5%, 50% 25%, 60% 15%, 75% 30%, 85% 20%, 90% 40%, 70% 50%, 85% 70%, 65% 60%, 50% 85%, 35% 70%, 20% 80%, 10% 60%, 5% 40%);
}

.engine-logo {
  position: absolute;
  z-index: 0;
}

.godot-logo {
  left: -150px;
  bottom: -180px;
  opacity: 0.2;
}

.unity-logo {
  right: -150px;
  bottom: -200px;
  opacity: 0.4;
}
</style>