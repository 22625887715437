<template>
  <v-card-title>About Us</v-card-title>
  <v-card-text>
    simmer.io is a place for indie game developers to share their games to the web browser with a single drag and drop. We've been compared to YouTube, but for games!
  </v-card-text>
  <v-card-text>
    We support Unity WebGL<span v-if="gating.showGodot"> and Godot</span> games, with more platforms coming in the future.
  </v-card-text>
</template>

<script setup>
import useGating from "~/composables/useGating";
const gating = useGating();
</script>
