<template>
  <div>
    <Hero :stats="stats" class="negative-margin"></Hero>

    <v-layout class="rounded rounded-md" style="z-index:1">

      <div class="hidden-sm-and-down left-panel pa-3">
        <FrontPageLeftPanel></FrontPageLeftPanel>
      </div>


      <v-main class="align-center justify-center" style="min-height: 300px; padding-top: 0;">
        <CardCollection
            v-for="(collection, index) in collections"
            use-complete-rows
            :key="index"
            :cardDataArray="collection.cardDataArray"
        >
          <template #prepend>
            <v-card-title v-if="collection.name" class="mb-2 pl-0">
              <span>{{collection.name}}&nbsp;</span>
              <span class="font-weight-black">&nbsp;{{collection.shortDescription}}&nbsp;</span><NuxtLink v-if="collection.friendlyUrl" :to="collection.friendlyUrl" class="all-link">all</NuxtLink>
            </v-card-title>
          </template>
          <template #append>
            <v-card-actions>
              <v-spacer></v-spacer>
              <NuxtLink v-if="collection.friendlyUrl" :to="collection.friendlyUrl">
                <v-btn>all "{{collection.name}}" projects</v-btn>
              </NuxtLink>
            </v-card-actions>
          </template>
        </CardCollection>
      </v-main>
    </v-layout>

  </div>
</template>

<script setup>
import {collectionItemArrayToCardDataArray} from "~/helpers/helpers";
import CardCollection from "~/components/cards/CardCollection.vue";

useHead({
  title: 'SIMMER.io - Indy Games Online'
});

const { data } = await useFetch('https://index.simmer.io/simmer/frontPage.json');

// todo lowpri: migrate this to index.simmer.io so we're not including stuff that's not needed.
const ordering = ['editors-choice', 'latest', 'founders-club', 'io-games', 'tutorials-and-tech-demos', 'unity-asset-store', 'playcanvas'];

const collections = Object.keys(data.value).map(collectionName => {
  const collection = data.value[collectionName];
  if (!collection.items) {
    return null;
  }
  //console.log(collection);
  const modifiedCollection = {
    cardDataArray : collectionItemArrayToCardDataArray(collection.items),
    shortName : collectionName,
    ...collection
  }
  modifiedCollection.friendlyUrl = collectionName === 'latest' ? '/latest/1' : `/c/${modifiedCollection.friendlyUrl}/1`;
  delete modifiedCollection.items;
  return modifiedCollection;
}).filter(collection => collection !== null && ordering.includes(collection.shortName))
    .sort((a, b) => ordering.indexOf(a.shortName) - ordering.indexOf(b.shortName));

const stats = `${data.value.stats.numGames.toLocaleString()} games | ${data.value.stats.numDevelopers.toLocaleString()} members`;

// console.log(data);
// console.log(collections);

</script>
<style scoped>
.negative-margin {
  margin-top: -64px;
}
.all-link {
  font-size: 0.8em;
  text-decoration: none;
}

.left-panel {
  border-right: 1px solid #333; /* Adjust the color and width as needed */
  background-color: #222;
  width:300px;
  min-width:300px;
}

</style>
